import React from 'react';
import { graphql } from 'gatsby';
import FlexibleLayout from '../../components/Acf/FlexibleLayout';
import { replaceWhiteSpace } from '../../util/basic-functions';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import SEO from '../../components/SEO/SEO';

export default ({ data, pageContext }) => {
  const { page, subNav, activeSubNavLabel, archiveNodes, archiveTerms } = data;
  const { title, jumpToLinks, flexibleLayout, seo } = page;

  return (
    <>
      <SEO seoData={seo} uri={page.uri} />
      <ContentLayout
        id={replaceWhiteSpace(title)}
        subNav={pageContext.subNavLocation ? subNav : null}
        activeSubNavLabel={activeSubNavLabel}
        pageContext={pageContext}
        pageTitle={title}
      >
        {flexibleLayout?.flexibleChildren &&
          flexibleLayout?.flexibleChildren.map((layout, index) => {
            return (
              <FlexibleLayout
                key={index}
                title={title}
                jumpToLinks={jumpToLinks}
                layout={layout}
                pageContext={pageContext}
                archiveNodes={archiveNodes}
                archiveTerms={archiveTerms}
              />
            );
          })}
      </ContentLayout>
    </>
  );
};

export const query = graphql`
  query page(
    $databaseId: Int!
    $nextPage: Int
    $previousPage: Int
    $subNavLocation: WpMenuLocationEnum
    $activeSubPath: String
    $archiveNodeType: String
    $archiveTermNodeType: String
    $termTaxonomyId: Int
    $skip: Int
    $limit: Int
  ) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      ...PageContent
    }
    subNav: wpMenu(locations: { eq: $subNavLocation }) {
      ...SubNavigation
    }
    activeSubNavLabel: wpMenuItem(locations: { eq: $subNavLocation }, url: { eq: $activeSubPath }) {
      label
    }
    nextPage: wpPage(databaseId: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(databaseId: { eq: $previousPage }) {
      title
      uri
    }
    archiveNodes: allWpContentNode(
      limit: $limit
      skip: $skip
      filter: {
        nodeType: { eq: $archiveNodeType }
        terms: { nodes: { elemMatch: { termTaxonomyId: { eq: $termTaxonomyId } } } }
      }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        nodeType
        ... on WpBlog {
          ...BlogPreviewContent
        }
        ... on WpUpdate {
          ...UpdatePreviewContent
        }
        ... on WpEvent {
          ...EventPreviewContent
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    archiveTerms: allWpTermNode(filter: { nodeType: { eq: $archiveTermNodeType } }) {
      nodes {
        uri
        name
      }
    }
  }
`;

export const fragments = graphql`
  fragment PageContent on WpPage {
    title
    content
    databaseId
    uri
    dateGmt
    modifiedGmt
    seo {
      ...SeoData
    }
    jumpToLinks {
      id
      jumpToLinkTitle
    }
    flexibleLayout {
      flexibleChildren {
        __typename
        ...Hero
        ...AfterHero
        ...AfterHeroStatement
        ...CardRow
        ...Grid
        ...Carousel
        ...VaspForm
        ...ZigZag
        ...PreFooter
        ...Faq
        ...ImageCta
        ...TitleCta
        ...JobBoard
        ...List
        ...LiveDataTracker
        ...TwoOverlappingImages
        ...WhitepaperSection
        ...InfographicVideo
        ...DeepDiveChapter
        ...DownloadPdf
        ...Document
        ...Thanks
        ...MissionStatement
        ...PostArchive
        ...BlogsPreview
        ...EventsPreview
        ...UpdatesPreview
        ...NetworkUpdatesPreview
        ...ClaSignCta
        ...ClaHelpPopup
        ...SectionBasedContent
        ...PrivacySectionBasedContent
        ...WhitepaperPage
        ...ClaSignCorporation
        ...ClaSignIndividual
        ...ClaSign
        ...Section
      }
    }
  }
`;
